#root {
  max-width:500px;
  margin:0 auto;
}

/* ### GIF */
#gif {
  margin-bottom:1rem;
  -webkit-border-radius:var(--border-radius);
  border-radius:var(--border-radius);
  overflow:hidden;
  box-shadow:var(--shadow);
}
#gif:after {
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.35);
  backdrop-filter:blur(3px);
  opacity:0;
  pointer-events: none;
  transition:opacity var(--transition);
}
#gif img {
  display:block;
  width:100%;
}

#gif form {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  -webkit-border-radius:0 0 var(--border-radius) var(--border-radius);
  border-radius:0 0 var(--border-radius) var(--border-radius);
  transition: transform var(--transition);
  transform: translateY(100%);
  z-index:1;
}
#gif form input[type="text"] {
  -webkit-border-radius:0 0 var(--border-radius) var(--border-radius);
  border-radius:0 0 var(--border-radius) var(--border-radius);
  box-shadow:none;
}
#gif form input[type="submit"] {
  -webkit-border-radius:0 0 var(--border-radius) 0;
  border-radius:0 0 var(--border-radius) 0;
}

#gif.active:after {opacity:1;}
#gif.active form {
  transform:translateY(0);
}

/* ### Form */
label {
  display:block;
  width:100%;
  -webkit-border-radius: var(--border-radius);
  border-radius: var(--border-radius);
}

input[type="text"] {
  width:100%;
  padding:0.5rem 4.5rem 0.5rem 0.75rem;
  margin:0;
  font-size:1rem;
  background-color:white;
  outline:0!important;
  border:0!important;
  -webkit-border-radius:var(--border-radius);
  border-radius:var(--border-radius);
  box-shadow: var(--shadow);
  transition:box-shadow var(--transition);
}
input[type="text"]:focus {box-shadow:0 2px 5px 0 rgb(100 100 100);}
input[type="submit"] {
  -webkit-appearance: none;
  display:block;
  position:absolute;
  top:0;
  right:0;
  height:100%;
  padding:0.5rem 0.75rem;
  margin:0;
  color:inherit;
  background-color:rgb(236,236,236);
  font-size:0.8rem;
  text-transform:uppercase;
  vertical-align: middle;
  border:0!important;
  outline:0!important;
  -webkit-border-radius: 0 var(--border-radius) var(--border-radius) 0;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  box-shadow:none!important;
  transition:var(--transition);
}
input[type="submit"]:active {
  background-color:rgb(160 160 160);
}

/* ### List */
ul {
  padding-left:0;
  list-style-type: none;
}

ul li {
  display:flex;
  flex-flow:row nowrap;
  justify-content: flex-start;
  align-items:center;
  margin-bottom:1rem;
}
ul li span:first-child {flex-basis:1.5rem;}
ul li span:last-child {flex-basis:calc(100% - 1.5rem);}

.remove-item {
  display:inline-flex;
  justify-content: center;
  align-items: center;
  height:1.5rem;
  width:1.5rem;
  margin-right:0.5rem;
  background-color:white;
  font-size:0.75rem;
  line-height:1.5rem;
  -webkit-border-radius:50%;
  border-radius:50%;
}

/* ### Dark Mode */
@media (prefers-color-scheme: dark) {
  input[type="text"] {
    background-color: rgb(70,70,70);
    color:white;
  }
  input[type="text"]::placeholder {color:rgb(200 200 200);}
  input[type="submit"] {background-color:rgb(120 120 120);}
  input[type="submit"]:active {background-color:rgb(100 100 100);}
  .remove-item {background-color:rgb(70,70,70);color:white!important;}
}
