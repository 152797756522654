@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --font-family:'Comic Neue', sans-serif;
  --border-radius: 8px;
  --shadow:0 1px 2px 0px rgb(100 100 100);
  --transition:0.3s ease;
}

* {
  position:relative;
  box-sizing:border-box;
  font-weight:600;
  font-family:var(--font-family);
  text-transform: uppercase;
}

html {font-size:16px;}

body {
  padding: 1.5rem 5vw;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html:before {
  content:'';
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  min-height:100vh;
  height:100%;
  background-image:url(https://www.flipnational.org/wp-content/uploads/2017/11/Triangle-White-Seamless-Patterns.jpg);
  background-repeat: repeat;
  opacity:0.25;
}

/* ### Dark Mode */
@media (prefers-color-scheme: dark) {
  body {
    background-color:rgb(30 30 30)!important;
    color:rgb(200 200 200) !important;
  }
}
